<template>
  <div eagle-photo-selector="root" :multiple="multiple" :single="!multiple">
    <!-- 單圖模式 -->
    <template v-if="!multiple">
      <photoItem
        :type="type"
        :photoManager="photoManager"
        :disabledRemove="disabledRemove || readonly"
        :options="options"
        :disabled="disabled || readonly"
        v-model="photo"
      ></photoItem>
      <input
        data-type="photo"
        :name="names[0]"
        :value="toString(photo)"
        :form="formKey"
        v-bind="Attr[0]"
        hidden
      />
    </template>
    <!-- 多圖模式 -->

    <!-- 一次上傳多張圖片 -->
<!--    <v-btn-->
<!--      depressed-->
<!--      v-if="multiple && !readonly"-->
<!--      small-->
<!--      class="mb-8"-->
<!--      outlined-->
<!--      color="green"-->
<!--      :loading="multipleUploadLoading"-->
<!--      @click="browserMultiPhoto"-->
<!--    >-->
<!--      <v-icon left>fa fa-image</v-icon>-->
<!--      <span>{{ "action.tinymce.upload_photos" | t }}</span>-->

<!--      <invisible-photo-uploader-->
<!--        ref="invisiblePhotoUploader"-->
<!--        @onLoadingChange="onLoadingChange"-->
<!--        @appendPhoto="appendPhoto"-->
<!--        multiple-->
<!--        :type="type"-->
<!--      ></invisible-photo-uploader>-->
<!--    </v-btn>-->
    <label v-show="multiple && simpleMode && photo.length < total" for="photo-upload-btn" class="custom-file-upload d-block mb-2">
      <v-btn
        color="primary"
        small
        :loading="multipleUploadLoading"
        @click="browserMultiPhoto"
        v-show="multiple && simpleMode && photo.length < total"
      >上傳圖片</v-btn>
      <invisible-photo-uploader
        ref="invisiblePhotoUploader"
        @onLoadingChange="onLoadingChange"
        @appendPhoto="appendPhoto"
        :type="type"
      ></invisible-photo-uploader>
    </label>
    <v-container v-if="multiple">
      <v-draggable
        v-if="!readonly"
        v-bind="dragOptions"
        v-model="photo"
        class="row justify-center"
        eagle-photo-selector="photo-list-container"
      >
        <template v-for="(photoItem, index) in photo">
          <photoItem
            :type="type"
            class="mr-4 mb-4"
            :draggable="draggable"
            :simpleMode="simpleMode"
            :key="`photo-item-${index}`"
            :photoManager="photoManager"
            :disabledRemove="disabledRemove || readonly"
            :options="options"
            :disabled="disabled || readonly"
            v-model="photo[index]"
          ></photoItem>
          <input
            data-type="photo"
            :key="index"
            :value="toString(photo[index])"
            :name="names[index]"
            :form="formKey"
            hidden
          />
        </template>
      </v-draggable>

      <div v-else class="row justify-center">
        <template v-for="(photoItem, index) in readonlyPhotos">
          <photoItem
            :type="type"
            class="mr-4 mb-4"
            :key="`photo-item-readonly-${photoItem.id}`"
            :photoManager="photoManager"
            disabledRemove
            :options="options"
            disabled
            :value="photoItem"
          ></photoItem>
          <input
            data-type="photo"
            :key="index"
            :value="toString(photoItem)"
            :name="names[index]"
            :form="formKey"
            hidden
          />
        </template>
      </div>
    </v-container>
  </div>
</template>

<script lang="babel" type="text/babel">
import photoItem from './photoItem.vue'
export default {
  name: 'photoSelector',
  props: {
    disabledRemove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: null,
    },
    value: {
      type: [Object, Array],
      default: () => null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'default',
    },
    options: {
      type: Object,
      default: () => ({

      }),
    },
    photoManager: {
      type: Boolean,
      default: false,
    },
    names: {
      type: Array,
      default: () => ([])
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    formKey : {
      type : String
    },
    // hasPhoto: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data: () => ({
    multipleUploadLoading: false,
    photo: null,
    Attr : []
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      if(!this.multiple) {
        this.initSinglePhoto()
        return
      }

      this.initMultiPhoto()
    },
    initSinglePhoto() {
      this.photo = window.eagleLodash.cloneDeep(this.value)
    },
    initMultiPhoto() {
      let photo
      if(!Array.isArray(this.value)) {
        photo = []
      }

      else {
        photo = window.eagleLodash.cloneDeep(this.value)
      }

      if(this.total) {
        if (this.simpleMode) {
          photo = photo.filter(v => v)
        } else {
          // 自動補滿圖片總量限制
          for(let index=0; index<this.total; index++) {
            if(photo[index] === undefined) {
              photo.push(null)
            }
          }
        }
      }

      this.photo = photo
    },
    onLoadingChange(status) {
      this.multipleUploadLoading = status
    },
    browserMultiPhoto() {
      this.$refs.invisiblePhotoUploader.openFileBrowser()
    },
    async appendPhoto(newPhoto) {
      if(!Array.isArray(this.photo)) {
        this.initMultiPhoto()
        await this.$nextTick()
      }
      let photo = window.eagleLodash.cloneDeep(this.photo)
      photo.push(newPhoto)
      // photo.pop()
      this.photo = photo
    },
    async setAttr() {
      await this.$nextTick();
      if(this.multiple) {
        this.photo.forEach((item,index) => {
          if(item) {

            this.Attr[index] = { name : this.names[index] , form : this.formKey }
          }
        });
      }
    },
    toString(photo) {
      return JSON.stringify(photo)
    }
  },
  computed: {
    quantity() {
      if(!this.photo) return 0
      if(!this.multiple) return 1
      if(!Array.isArray(this.photo)) return 0
      return this.photo.length
    },
    draggable() {
      if(!this.multiple) return false
      if(!Array.isArray(this.photo)) return false
      return this.photo.length > 1 && this.isDraggable
    },
    dragOptions() {
      return {
        disabled: this.draggable != true,
        handle: '.photo-selector-draggable',
      }
    },
    readonlyPhotos() {
      return this.photo.filter(item => !!item)
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.init()
      },
    },
    photo: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.value, this.photo)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.photo))
        this.setAttr()
        
        if(this.multiple) {
          this.$emit('update:getHasPhoto', this.photo.filter( item => item != null ).length > 0 )
        } else {
          this.$emit('update:getHasPhoto', !!this.photo )
        }
        
      },
    },
  },
  components: {
    photoItem,
  },
}
</script>

<style lang="sass" type="text/sass">
*[eagle-photo-selector="root"]
  &[single]
    width: 200px
</style>
